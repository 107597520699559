import styled from 'styled-components'

export default styled.h1`
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 1rem;
  letter-spacing: 6px;
  padding-left: ${props => (props.padded ? 'var(--pad4);' : '0')};
  text-align: left;
  font-weight: 900;
  text-shadow: -1.1px -1.1px 0 var(--offPrimeGray), 1.1px -1.1px 0 var(--offPrimeGray),
    -1.1px 1.1px 0 var(--offPrimeGray), 1.1px 1.1px 0 var(--offPrimeGray);
  }
  color: var(--bgColor);
  text-transform: uppercase;
  transform-origin: center left;
  z-index: -1;
`
