import React from 'react'
import PageTitle from '../components/PageTitle'
import PageWrapper from '../components/PageWrapper'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Text from '../components/Text'
import Extrude from '../components/Extrude'
import styled from 'styled-components'

const SVGWrapper = styled.svg`
transform: var(--perspectiveTransform);
transform-origin: center;
`
const OrangeRect = styled.rect`
fill: var(--primeColor)
`

const HeroText = styled.text.attrs({
  style: ({ xRotate }) => ({
    transform: `rotateY(-${xRotate/2}deg)`,
  }),
})`
filter: url(#outline);
font-size: 20em;
fill: var(--primeColor);
font-weight: 900;
`

const StrokeEllipse = styled.ellipse`
stroke:  var(--primeColor);
stroke-width: 10;
fill: white;
`

const constrain = min => max => v =>{
  if ( v < min ) {
    return min
  }
  if ( v > max ) {
    return max
  }
  return v
}

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { x: 0, y: 0 };
    this.minX = -20;
    this.maxX = 25;
  }
  onMouseMove (e) {
    const newX = this.state.x + e.movementX/5;
    requestAnimationFrame(() => {
      this.setState({ x: constrain(this.minX)(this.maxX)(newX) });
    })
  }
  render() {
    const radius = 400;
    return (
      <Layout onMouseMove={this.onMouseMove.bind(this)} location={this.props.location}>
        <SEO title="404: Not Found" />

        <PageWrapper>
        <Text>
        <SVGWrapper onMouseMove={this.onMouseMove.bind(this)} xRotate={this.state.x} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
        <filter id={ `outline` } width="140%" x="-30%" height="160%" y="-20%">
            <feMorphology operator="dilate" radius="5" in="SourceGraphic" result={`fat`} />
            <Extrude count={15} offsetFactor={0.4} />
            <feComposite operator="out" in={`fat`} in2="SourceGraphic" result="prime"></feComposite>
            <feMerge>
                <feMergeNode in="prime" />
                <feMergeNode in="shadow" />
            </feMerge>
        </filter>
        <clipPath id={`front-circle-clip`}>
        <ellipse cx="500" cy="500" rx={radius} ry={radius} /> 
          </clipPath>
          <clipPath id={`back-circle-clip`}>
        <StrokeEllipse clipPath="url(#front-circle-clip)" cx={(this.state.x * -2) + 500} cy="500" rx={radius} ry={radius} />
          
          </clipPath>
          <StrokeEllipse cx="500" cy="500" rx={radius} ry={radius} />
          <g clipPath="url(#front-circle-clip)">
          <OrangeRect x="-10" y="-10" height="1020" width="1020" />
          <StrokeEllipse  cx={(this.state.x * -2) + 500} cy="500" rx={radius} ry={radius} />
          <HeroText clipPath="url(#back-circle-clip)"  xRotate={this.state.x}   x={this.state.x + 150}  y="600">404</HeroText>

          </g>
        </SVGWrapper>
        <PageTitle>Not Found</PageTitle>
        <p>The page you are requesting was not found.</p>
       
        </Text>
        </PageWrapper>
      </Layout>
    )
  }
}

export default NotFoundPage
